<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12 class="align-self-start">
        <v-layout wrap justify-center>
          <v-flex>
            <ForgotPassword v-if="winStep == 0" />
            <LoginVerify
              v-if="winStep == 1"
              v-bind:pageData="pageData"
              @stepper="winStepper"
              v-bind:lastPage="lastPage"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import ForgotPassword from "./../Authentication/forgotPassword";
import LoginVerify from "./../Authentication/loginVerify";
export default {
  components: {
    ForgotPassword,
    LoginVerify,
  },
  data() {
    return {
      winStep: 0,
      phone: null,
      pageData: {},
      lastPage: null,
    };
  },
  beforeMount() {
    if (this.$route.params.lastPage)
      this.lastPage = this.$route.params.lastPage;
  },
  methods: {
    winStepper(window_data) {
      this.winStep = window_data.winStep;
      this.pageData.phone = window_data.phone;
      if (window_data.response)
        this.pageData.pageResponse = window_data.response;
    },
  },
};
</script>
<style>
.login_page {
  background-color: #2aa86638;
  background-image: url("./../../assets/images/bg.jpg");
  background-attachment: fixed;
  background-size: cover;
}
</style>