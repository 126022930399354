<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-app id="inspire">
      <v-layout wrap justify-center class="login_page">
        <v-flex xs12 sm6 md4 lg3 px-2 align-self-center>
          <v-layout wrap justify-center>
            <v-flex xs112 xl10>
              <v-snackbar
                v-model="showSnackBar"
                color="#FDCE48"
                right
                :timeout="timeout"
              >
                <v-layout wrap justify-center>
                  <v-flex text-left class="align-self-center">
                    <span style="color: #000">
                      {{ msg }}
                    </span>
                  </v-flex>
                  <v-flex text-right>
                    <v-btn
                      small
                      :ripple="false"
                      text
                      @click="showSnackBar = false"
                    >
                      <v-icon style="color: #000">mdi-close</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-snackbar>
              <v-card tile flat>
                <v-layout wrap justify-center>
                  <v-flex px-8 py-6>
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <span
                          style="
                            font-family: poppinsmedium;
                            font-size: 15px;
                            color: #b8b8b8;
                          "
                        >
                          Welcome to
                        </span>
                      </v-flex>
                      <v-flex xs12 py-4 style="cursor: pointer">
                        <router-link to="/">
                          <span
                            style="
                              font-family: poppinsbold;
                              font-size: 20px;
                              cursor: pointer;
                              color: #545454;
                            "
                          >
                            Hive Keep
                          </span>
                        </router-link>
                      </v-flex>
                      <v-flex xs12>
                        <v-layout wrap justify-center>
                          <v-flex xs8 xl7 px-2>
                            <span
                              style="
                                font-family: poppinssemibold;
                                font-size: 15px;
                                color: #000;
                              "
                            >
                              Forgot Password
                            </span>
                            <v-progress-linear
                              height="2"
                              value="100"
                              color="#FDCE48"
                            ></v-progress-linear>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex pt-8 xs12 text-left>
                        <v-layout wrap justify-center>
                          <v-flex xs12 pb-2>
                            <span
                              style="
                                font-family: ;
                                font-size: 12px;
                                color: #ababab;
                              "
                            >
                              Username
                            </span>
                          </v-flex>
                          <v-flex xs12>
                            <v-form @submit.prevent="validateInput">
                              <v-text-field
                                color="#717171"
                                placeholder="Username"
                                outlined
                                dense
                                v-model="username"
                                hide-details
                              >
                              </v-text-field>
                            </v-form>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap justify-center pt-2>
                          <v-flex xs12 pb-2>
                            <span
                              style="
                                font-family: ;
                                font-size: 12px;
                                color: #ababab;
                              "
                            >
                              Password
                            </span>
                          </v-flex>
                          <v-flex xs12>
                            <v-form @submit.prevent="validateInput">
                              <v-text-field
                                color="#717171"
                                placeholder="Password"
                                type="password"
                                outlined
                                dense
                                v-model="password"
                                hide-details
                              >
                              </v-text-field>
                            </v-form>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex pt-2 xs12 text-left>
                        <router-link to="/Login">
                          <span
                            style="
                              font-family: poppinssemibold;
                              font-size: 12px;
                              text-transform: none;
                            "
                          >
                            Back to Login
                          </span>
                        </router-link>
                      </v-flex>
                      <!-- <v-flex pt-2 xs12 text-left>
                        <v-layout wrap justify-center pt-2>
                          <v-flex xs12 pb-2>
                            <span
                              style="
                                font-family: ;
                                font-size: 12px;
                                color: #ababab;
                              "
                            >
                              Role
                            </span>
                          </v-flex>
                          <v-flex xs12>
                            <v-select
                              v-model="userType"
                              :items="userTypes"
                              item-color="#000000"
                              color="#000000"
                              outlined
                              hide-details
                              dense
                            >
                              <template v-slot:item="{ item }">
                                <span class="text-left">{{ item }}</span>
                              </template>
                            </v-select>
                          </v-flex>
                        </v-layout>
                      </v-flex> -->
                      <v-flex xs12 py-6>
                        <v-btn
                          block
                          tile
                          color="#FDCE48"
                          light
                          :ripple="false"
                          depressed
                          @click="validateInput"
                          class="itemValue"
                        >
                          Continue
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-app>
  </div>
</template>
<script>
import axios from "axios";
import store from "./../../store";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      username: null,
      password: null,
      userType: "User",
      userTypes: ["User", "Supervisor"],
    };
  },
  methods: {
    validateInput() {
      if (!this.username) {
        this.msg = "Please Provide Username";
        this.showSnackBar = true;
        return;
      } else if (!this.password) {
        this.msg = "Please Provide Password";
        this.showSnackBar = true;
        return;
      } else {
        this.login();
      }
    },
    login() {
      var userData = {};
      userData["username"] = this.username;
      userData["password"] = this.password;
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/login",
        data: userData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            store.commit("userType", response.data.data.role);
            store.commit("userData", response.data.data);
            store.commit("loginUser", response.data.data.token);
          } else {
            if (response.data.availableroles) {
              this.$emit("stepper", {
                response: response.data,
                auth: userData,
                winStep: 1,
              });
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>